<template>
  <div class="create-class-ticket">
    <div class="card-container wrapper">
      <div
        class="font-bold text-lg text-primary-100"
        style="margin-bottom: 16px"
      >
        {{ showEditPunchCard ? '編輯次卡' : '新增次卡' }}
      </div>
      <GrayBlockContainer v-if="useEntryControl" class="w-[560px] mb-[20px]">
        <p class="text-sub text-gray-80">如需用於門市進場，請至「基本參數設定 > 進場模組設定 > 編輯進場時段與收費模式」將此次卡綁定至對應的門市、進場時段。</p>
      </GrayBlockContainer>
      <BaseElForm
        ref="formRef"
        label-position="top"
        :model="formData"
        :rules="formRules"
      >
        <BaseElFormItem label="名稱" prop="name">
          <BaseElInput
            v-model="formData.name"
            data-cy="name-input"
            placeholder="次卡名稱"
          />
        </BaseElFormItem>
        <BaseElFormItem
          label="圖片"
          prop="image"
          class="form-relative-label others"
        >
          <UploadButton
            cyUploadBtn="ticket-img-upload-btn"
            cyUploadedImg="ticket-img-uploaded"
            :img="formData.avatar"
            :isAvatar="true"
            @change="loadImg"
          />
        </BaseElFormItem>
        <!-- <BaseElFormItem label="有效期限" prop="isExp">
          <BaseElSwitch
            v-if="showCreateOption"
            v-model="formData.isExp"
            active-text="開啟"
            inactive-text="關閉"
            @change="isExpChange"
          />
          <p v-if="showEditPunchCard" class="form-edit-disable">
            {{ formData.isExp ? '開啟' : '關閉' }}
          </p>
        </BaseElFormItem> -->
        <BaseElFormItem v-if="!punchCardId || copy" label="有效期限" prop="expType">
          <div class="grid gap-[8px] items-center grid-cols-2 w-[560px]">
            <BaseElSelect
              v-model="formData.expType"
              name="isExp"
              class="w-full"
              @change="onExpTypeChange"
              :disabled="formData.maxDayUnlimited"
            >
              <BaseElSelectOption label="有效天數" value="day" />
              <BaseElSelectOption label="特定截止日期" value="date" />
            </BaseElSelect>

            <BaseElFormItem v-if="formData.expType === 'day'" prop="expDay">
              <BaseElInputNumber
                v-model="formData.expDay"
                :min="1"
              />
            </BaseElFormItem>
            <BaseElFormItem v-if="formData.expType === 'date'" prop="expDate">
              <el-date-picker
                v-model="formData.expDate"
                editable
                type="date"
                format="yyyy-MM-dd"
                placeholder="選擇日期"
                :picker-options="pickerOptions"
              />
            </BaseElFormItem>
          </div>
          <BaseElCheckbox
            v-model="formData.maxDayUnlimited"
            @change="onMaxDayUnlimitedChange"
          >
            無期限
          </BaseElCheckbox>
        </BaseElFormItem>
        <BaseElFormItem v-if="showEditPunchCard" label="有效天數">
          <p class="form-edit-disable">
            {{ expireSettingContent }}
          </p>
        </BaseElFormItem>

        <BaseElFormItem v-if="showEditPunchCard && formData.expDate" label="特定截止日期">
          <p class="form-edit-disable">
            {{ `${formatDate(formData.expDate, 'YYYY/MM/DD HH:mm:ss')}` }}
          </p>
        </BaseElFormItem>
        <BaseElFormItem label="可使用次數" prop="totalAmount">
          <div class="flex flex-col w-[560px]">
            <BaseElInputNumber
              v-if="showCreateOption"
              v-model="formData.totalAmount"
              :min="1"
              :disabled="formData.maxAmountUnlimited"
            />
            <BaseElCheckbox
              v-if="showCreateOption"
              v-model="formData.maxAmountUnlimited"
              @change="onMaxAmountUnlimitedChange"
            >
              無上限
            </BaseElCheckbox>
            <p v-if="showEditPunchCard" class="form-edit-disable">
              {{ `${formData.totalAmount} 次` }}
            </p>
          </div>
        </BaseElFormItem>
        <BaseElFormItem label="敘述" prop="description" class="w-[560px]">
          <quillEditor
            ref="quill"
            v-model="formData.description"
            :options="editorOption"
          />
        </BaseElFormItem>
        <BaseElFormItem label="注意事項" prop="note" class="w-[560px]">
          <quillEditor
            ref="note"
            v-model="formData.note"
            :options="editorOption"
          />
        </BaseElFormItem>
        <BaseElFormItem label="排序" prop="order">
          <BaseElInput
            v-if="showCreateOption"
            v-model="formData.order"
            data-cy="order-input"
            placeholder="請輸入排序"
          />
          <p v-if="showEditPunchCard" class="form-edit-disable">{{ formData.order }}</p>
        </BaseElFormItem>
      </BaseElForm>
    </div>

    <ImageCropper
      v-if="uploadDialog"
      :image="formData.img"
      @uploaded="getImage"
      @close="uploadDialog = false"
    />
    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="$router.push({ name: 'ClassTicketSetting' })"
      @confirm="handleConfirm"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, set } from 'vue'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { noEmptyRules, noPastDate, maxNumberRules } from '@/validation'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { usePermissions } from '@/use/permissions'
import {
  CreatePunchCard,
  FindPunchCard,
  UpdatePunchCard,
} from '@/api/punchCard'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router/composables'
import formUtils from '@/utils/form'
import { useBaseForm, mappingSyncFormData, onFormRulesChangeClearValidate } from '@/use/useForm'
import { useFetch } from '@/use/fetch'
import { formatDate } from '@/utils/date'
import { map, keys, has, get } from 'lodash'
import dayjs from '@/lib/dayjs'
export default defineComponent({
  name: 'createPunchCard',
  components: { UploadButton, quillEditor, ImageCropper, GrayBlockContainer },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const {
      initFormData, formData, formRef, loading,
    } = useBaseForm()
    const { simpleFetch } = useFetch()
    const shopId = computed(() => store.getters.shop)
    const punchCardId = computed(() => route.params.punchCardId)
    const copy = computed(() => route.query.copy)
    const showCreateOption = computed(() => !punchCardId.value || copy.value)
    const showEditPunchCard = computed(() => punchCardId.value && !copy.value)
    const avatarChanged = ref(false)
    const uploadDialog = ref(false)
    const { checkAction, checkActionList } = usePermissions()

    const useEntryControl = computed(() => true) // TODO: 開啟進場模組

    initFormData({
      name: null,
      description: null,
      note: null,
      image: null,
      order: 100,
      totalAmount: 1,
      // avatar: null,
      // isExp: false,
      expType: null,
      expDay: null,
      expDate: null,
      expireSetting: null,
      // type: null,
      // afterReceiveDays: null,
      // fixedDate: null,
      maxAmountUnlimited: false,
      maxDayUnlimited: false,
      // clientUsageTimesMax: 1,
      // clientUsageTimes: false,
      // isValuable: true,
      // isClientUsageAllowed: false,
      // expType: null,
      // appointmentServices: null,
    })

    const pickerOptions = {
      disabledDate: (date) => {
        return dayjs(date).isSameOrBefore(dayjs(), 'd')
      },
    }

    const editorOption = {
      placeholder: '請輸入...',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'bullet' }],
          ['link'],
        ],
      },
    }

    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
        order: [noEmptyRules()],
        description: [noEmptyRules()],
        totalAmount: [noEmptyRules(), maxNumberRules(999)],
        expType: [noEmptyRules()],
      // note: noEmptyRules(),
      }

      // if (formData.isExp) rules.expType = [noEmptyRules()]
      if (formData.maxDayUnlimited) rules.expType = []
      if (formData.expType === 'day') rules.expDay = [noEmptyRules()]
      if (formData.expType === 'date') rules.expDate = [noEmptyRules(), noPastDate()]
      return rules
    })

    onFormRulesChangeClearValidate(formRef, formRules)
    const expireSettingContent = computed(() => {
      const type = get(formData, 'expireSetting.type')
      switch (type) {
      case 'forever':
        return '無期限'
      case 'afterReceive':
        return `領取後${get(formData, 'expireSetting.afterReceiveDays')}天`
      case 'fixedDate':
        return formatDate(get(formData, 'expireSetting.fixedDate'), 'YYYY/MM/DD')
      default:
        return ''
      }
    })
    const loadImg = (img) => {
      formData.img = img
      uploadDialog.value = true
    }

    const getImage = (data) => {
      formData.avatar = data
      avatarChanged.value = true
      uploadDialog.value = false
    }

    //  新增堂票
    const createPunchCard = async () => {
      const {
        name,
        description,
        note,
        order,
        totalAmount,
        // type,
        // afterReceiveDays,
        // fixedDate,
        avatar,
        // isExp,
        expType,
        expDay,
        expDate,
        maxDayUnlimited,
        // isValuable,
        // isClientUsageAllowed,
        // appointmentServices,
      } = formData
      const expireSettingType = maxDayUnlimited ? 'forever' : (expType === 'day' ? 'afterReceive' : 'fixedDate');
      const [, err] = await CreatePunchCard({
        shopId: shopId.value,
        name,
        description,
        note: note || undefined,
        order,
        totalAmount,
        expireSetting: {
          type: expireSettingType,
          afterReceiveDays: expDay || 1,
          fixedDate: expDate || undefined,
        },
        // isExp,
        // expDay: Number(expDay) || undefined,
        // specifyExpiredDate: expDate ? dayjs(expDate).endOf('d').toDate() : undefined,
        // isValuable: isValuable,
        // isClientUsageAllowed,
        image: avatar?.id,
        imageId: avatar?.id,
        // appointmentServices: map(appointmentServices, 'id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('新增成功!')
      router.push({ name: 'PunchCardSetting' })
    }

    //  更新堂票，id經由syncData寫入
    const updatePunchCard = async () => {
      const {
        name,
        description,
        note,
        order,
        totalAmount,
        avatar,
        expType,
        expDay,
        expDate,
        maxDayUnlimited,
        id,
      } = formData
      const expireSettingType = maxDayUnlimited ? 'forever' : (expType === 'day' ? 'afterReceive' : 'fixedDate');
      const [, err] = await UpdatePunchCard({
        shopId: shopId.value,
        id,
        name,
        description,
        note: note || undefined,
        totalAmount,
        expireSetting: {
          type: expireSettingType,
          afterReceiveDays: expDay || 1,
          fixedDate: expDate || undefined,
        },
        image: avatar?.id,
        order,
        // appointmentServices: map(appointmentServices, 'id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功!')
      router.push({ name: 'PunchCardSetting' })
    }

    const syncData = (res) => {
      mappingSyncFormData({ Image: 'avatar', specifyExpiredDate: 'expDate', AppointmentServices: 'appointmentServices', expDate: 'specifyExpiredDate' }, res, formData)
      if (formData.expDate) formData.expType = 'date'
      else if (formData.expDay) formData.expType = 'day'
    }

    const handleConfirm = async () => {
      loading.value = true
      const pass = await formUtils.checkForm(formRef.value)
      if (!pass) {
        loading.value = false
        return
      }
      if (showEditPunchCard.value) await updatePunchCard()
      else await createPunchCard()
      loading.value = false
    }

    const onExpTypeChange = () => {
      formData.expDay = null
      formData.expDate = null
    }
    const onMaxAmountUnlimitedChange = (toggle) => {
      if (toggle) formData.totalAmount = null
    }
    const onMaxDayUnlimitedChange = (toggle) => {
      if (toggle) formData.expType = null
    }
    // const isExpChange = () => {
    //   formData.expDay = null
    //   formData.expDate = null
    //   formData.expType = null
    // }

    onMounted(() => {
      if (punchCardId.value) {
        simpleFetch(FindPunchCard, {
          shopId: shopId.value,
          id: punchCardId.value,
        },
        (res) => {
          syncData(res)
        })
      }
    })

    return {
      pickerOptions,
      punchCardId,
      formData,
      editorOption,
      loadImg,
      uploadDialog,
      getImage,
      formRules,
      handleConfirm,
      loading,
      formRef,
      formatDate,
      onExpTypeChange,
      // isExpChange,
      copy,
      showEditPunchCard,
      showCreateOption,
      useEntryControl,
      onMaxAmountUnlimitedChange,
      onMaxDayUnlimitedChange,
      expireSettingContent,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-select .el-input {
  @apply w-full;
}

::v-deep .el-input-number,
::v-deep .el-input-number .el-input,
::v-deep .el-date-editor {
  @apply w-full;
}

.wrapper {
  padding: 20px !important;
  padding-left: 29px !important;
}

.form-relative-label {
  @apply relative;
}

.form-relative-label.others::after {
  content: '(建議上傳尺寸1200x1200)';
  @apply absolute left-[50px] top-[2px] text-sm text-gray-80;
}

.el-form-item__label {
  @apply p-0;
}

.ql-container {
  @apply h-[200px];
}

.form-edit-disable {
  color: #606266;
}
</style>
